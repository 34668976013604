import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useContext, useEffect } from 'react';

import { UIContext, UIReducerTypes } from '@/context/UIContext';

import NavBar from '../NavBar';
import Footer from './Footer';

const ShopLayout: NextPage<{
  children?: ReactNode;
  fullscreen?: boolean;
  fill?: boolean;
  dark?: boolean;
  hideBlurAtTop?: boolean;
  useWhiteHeader?: boolean;
}> = ({
  children,
  fullscreen = false,
  fill = true,
  dark = false,
  hideBlurAtTop = false,
  useWhiteHeader = false,
}) => {
  const { dispatch } = useContext(UIContext);
  const { query } = useRouter();

  // const showCart = () => {
  //   dispatch({ type: UIReducerTypes.ShowCart });
  //   viewBagAnalytics();
  // };

  // const hideCart = () => {
  //   dispatch({ type: UIReducerTypes.HideCart });
  // };

  useEffect(() => {
    if (query.cart === 'true') {
      dispatch({ type: UIReducerTypes.ShowCart });
    }
  }, [dispatch, query]);

  return (
    <div className="relative">
      <NavBar dark={dark} hideBlurAtTop={hideBlurAtTop} />

      {/* {!!state?.cart && (
        <Portal onClose={hideCart}>
          <motion.div
            initial={{ translateX: "100%" }}
            animate={{ translateX: "0%" }}
            transition={{ ease: "easeOut", duration: 1 }}
            className="grid grid-cols-12 w-full lg:max-w-[600px] overflow-y-scroll bg-alter-bone"
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              padding: "24px 32px 16px 32px",
            }}
          >
            <div className="col-span-12">
              <Cart />
            </div>
          </motion.div>
        </Portal>
      )} */}
      {children}

      <Footer />
    </div>
  );
};

export default ShopLayout;
