import DownloadAppStore from '@public/icons/download-app-store.svg';
import DownloadGooglePlay from '@public/icons/download-google-play.svg';
import LogoType from '@public/icons/logotype-white.svg';
import Amex from '@public/icons/payment_providers/amex.svg';
import ApplePay from '@public/icons/payment_providers/apple-pay.svg';
import DinersClub from '@public/icons/payment_providers/diners-club.svg';
import Discover from '@public/icons/payment_providers/discover.svg';
import Mastercard from '@public/icons/payment_providers/mastercard.svg';
import PayPal from '@public/icons/payment_providers/paypal.svg';
import Visa from '@public/icons/payment_providers/visa.svg';
import { ChevronRightIcon, InstagramLogoIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { subscribeToNewsletterAnalytics } from '@/utils/analytics';

const PAYMENT_TYPES = [
  {
    src: Visa,
    alt: 'Visa',
  },
  {
    src: DinersClub,
    alt: 'Diners Club',
  },
  {
    src: Amex,
    alt: 'American Express',
  },
  {
    src: Discover,
    alt: 'Discover',
  },
  {
    src: Mastercard,
    alt: 'Mastercard',
  },
  {
    src: PayPal,
    alt: 'PayPal',
  },
  {
    src: ApplePay,
    alt: 'Apple Pay',
  },
];

const INFORMATION = [
  {
    title: 'Pricing',
    href: '/pricing',
  },
  {
    title: 'FAQ',
    href: '/faq',
  },
  {
    title: 'Alter System FAQs',
    href: '/alter-faq',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
];

const ABOUT = [
  {
    title: 'Press',
    href: '/press',
  },
  {
    title: 'Who We Are',
    href: '/who-we-are',
  },
  {
    title: 'AlterCares',
    href: '/alter-cares',
  },
  {
    title: 'Alter Your Career Here',
    href: 'https://try.alterme.com/jobs/',
  },
];

const SUPPORT = [
  {
    title: 'Contact Us',
    href: '/contactus',
  },
];

const LEGAL = [
  {
    title: 'Privacy Policy',
    href: '/legal/privacy-policy',
  },
  {
    title: 'Terms of Use',
    href: '/legal/terms-of-use',
  },
  {
    title: 'Limited Warranty',
    href: '/legal/limited-warranty',
  },
  // {
  //   title: "Cookie Settings",
  //   href: "/",
  // },
  // {
  //   title: "Accessibility",
  //   href: "/",
  // },
  // {
  //   title: "CA Transparency act",
  //   href: "/",
  // },
];

const INSTAGRAM_URL = 'https://www.instagram.com/alterme/';

const LinkCategory: React.FC<{
  title: string;
  links: { title: string; href: string }[];
}> = ({ title, links }) => {
  return (
    <div>
      <h4 className="text-alter-bone-60 font-light pb-4 text-base">{title}</h4>
      <nav>
        <ul>
          {links.map((link) => (
            <li key={link.href} className="text-alter-bone pb-3">
              <Link href={link.href}>{link.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

const NewsLetterSubscription = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const email = event.target.elements.email.value;
    if (!email) return;
    setIsSubmitted(true);
    subscribeToNewsletterAnalytics(email.trim().toLowerCase());
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-alter-bone text-xl font-light pb-6">Subscribe to our newsletter</p>
      {isSubmitted ? (
        <div className="flex gap-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M3 9L7 13L13 4"
              stroke="#85AE8C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="text-[#85AE8C] text-sm font-light">
            Success, you’ve subscribed to our newsletter.
          </p>
        </div>
      ) : (
        <div className="relative flex items-center gap-[10px] border-b-[1px] border-alter-bone-20">
          <input
            name="email"
            type="email"
            placeholder="Email address"
            className="bg-[transparent] py-3 w-[90%] outline-none placeholder-alter-bone font-light"
          />
          <button type="submit" className="h-2 -translate-y-1/2">
            <ChevronRightIcon width={20} height={26} className="text-alter-bone-60" />
          </button>
        </div>
      )}
    </form>
  );
};

const CopyrightAndPaymentTypes = ({ className }: { className?: string }) => {
  return (
    <section className={clsx('text-alter-bone-80 text-sm', className)}>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p>All Rights Reserved</p>
        <div className="flex gap-2 my-4">
          {PAYMENT_TYPES.map((type, index) => (
            <Image
              key={type.src + index}
              src={type.src}
              alt={type.alt}
              width={35}
              height={24}
              draggable={false}
            />
          ))}
        </div>
        <p>©2025 Fit Wind LLC</p>
      </div>
    </section>
  );
};

const MainFooterContent = () => {
  return (
    <section className="flex flex-col gap-y-10 text-alter-bone basis-1/3">
      <div>
        <Link href={'/'} passHref draggable={false}>
          <Image
            className="mb-4"
            width={69}
            height={24}
            src={LogoType}
            alt="Alter logo"
            draggable={false}
          />
        </Link>
        <p className="font-ModernGothicTrial font-light text-alter-bone-60">
          Alter is the world&apos;s first at-home fitness system powered by your genes and
          biometrics.
        </p>
      </div>
      <NewsLetterSubscription />
      <div>
        <p className="font-light pb-3">Connect with us on Instagram</p>
        <Link href={INSTAGRAM_URL} rel="noreferrer" target="_blank" draggable={false}>
          <InstagramLogoIcon width={24} height={24} />
        </Link>
      </div>
      <div className="flex gap-4">
        <Link
          href="https://apps.apple.com/us/app/alter/id1671228187"
          rel="noreferrer"
          target="_blank"
          draggable={false}
        >
          <Image
            src={DownloadAppStore}
            height={40}
            width={120}
            alt="Download on the App Store"
            draggable={false}
          />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.alter_v2"
          rel="noreferrer"
          target="_blank"
          draggable={false}
        >
          <Image
            src={DownloadGooglePlay}
            height={40}
            width={135}
            alt="Get it on Google Play"
            draggable={false}
          />
        </Link>
      </div>
    </section>
  );
};

const Footer: React.FC = () => {
  const defaultHPadding = 'px-4 md:px-20 lg:px-[80px]';
  return (
    <footer
      className="relative bg-black pt-8 md:pt-20"
      style={{ paddingBottom: 'calc(1em + env(safe-area-inset-bottom))' }}
    >
      <div className={defaultHPadding}>
        <div className="flex flex-col md:flex-row md:justify-between gap-x-2 gap-y-[52px] pb-4 md:pb-20 width-1440">
          <MainFooterContent />
          <LinkCategory title="Information" links={INFORMATION} />
          <LinkCategory title="About" links={ABOUT} />
          <LinkCategory title="Support" links={SUPPORT} />
          <LinkCategory title="Legal" links={LEGAL} />
        </div>
      </div>
      <hr className="relative mb-8 border-[#2B2B2B]" />
      <div className={defaultHPadding}>
        <CopyrightAndPaymentTypes className="width-1440" />
      </div>
    </footer>
  );
};

export default Footer;
